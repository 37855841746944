@import url('./global.css');

section.one {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
       -ms-flex-direction: column;
           flex-direction: column;
   gap: 40px;
}

section.one .error {
   position: absolute;
   top: 100%;
}

section.one .right-part {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
}

section.one .highlight {
   position: relative;
   display: -webkit-inline-box;
   display: -ms-inline-flexbox;
   display: inline-flex;
}

section.one .highlight::after {
   content: '';
   position: absolute;
   height: 60%;
   width: 3px;
   border-radius: 50px;
   right: -3px;
   top: 50%;
   -webkit-transform: translateY(-50%);
           transform: translateY(-50%);
   background: rgba(var(--purple-rgb), 1);
   -webkit-animation: blink 0.6s infinite linear;
           animation: blink 0.6s infinite linear;
}

section.one .highlight span.period {
   color: rgba(var(--charcoal-rgb), 1);
}

section.one .highlight span.isSpace {
   margin: 0 5px;
}

@-webkit-keyframes blink {
   0%,
   49% {
      opacity: 1;
   }
   50%,
   99% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

@keyframes blink {
   0%,
   49% {
      opacity: 1;
   }
   50%,
   99% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

section.two .scrolling-logos {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   overflow: hidden;
   position: relative;
}

section.two .scrolling-logos ul {
   gap: 0;
}

section.two .scrolling-logos::before {
   content: '';
   position: absolute;
   left: 0;
   top: 0;
   height: 100%;
   width: 60px;
   z-index: 1;
}

section.two .scrolling-logos::after {
   content: '';
   position: absolute;
   right: 0;
   top: 0;
   height: 100%;
   width: 60px;
   background: -webkit-linear-gradient(right, #fffbfb, rgba(255, 255, 255, 0));
   background: linear-gradient(to left, #fffbfb, rgba(255, 255, 255, 0));
   z-index: 1;
}

section.two .scrolling-logos ul {
   -ms-flex-preferred-size: 100%;
       flex-basis: 100%;
   -webkit-box-flex: 0;
       -ms-flex-positive: 0;
           flex-grow: 0;
   -ms-flex-negative: 0;
       flex-shrink: 0;
   -webkit-animation: scroll 25s infinite linear;
           animation: scroll 25s infinite linear;
}

@-webkit-keyframes scroll {
   0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
   }
   100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
   }
}

@keyframes scroll {
   0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
   }
   100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
   }
}

section.two li {
   width: 100%;
   margin: 0 30px;
}

section.two h1,
section.three h1,
section.value-props h1,
section.five h1 {
   margin-bottom: 30px;
}

section.three .left-part {
   width: 40%;
}

section.three ul {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
       -ms-flex-direction: column;
           flex-direction: column;
   gap: 20px;
}

section.three button {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 15px;
   width: 100%;
   height: 100%;
   -webkit-box-pack: start;
       -ms-flex-pack: start;
           justify-content: flex-start;
   border: 1px solid rgba(var(--blue-rgb), 1);
}

section.three button:hover {
   background: rgba(var(--blue-rgb), 1);
}

section.three button.active {
   background: rgba(var(--blue-rgb), 1);
   color: rgba(var(--milk-white-rgba), 1);
}

section.three i {
   font-size: 1.8rem;
}

section.three img {
   display: none;
}

section.three img.active {
   display: block;
}

section.three .right-part {
   width: 70%;
}

section.four button {
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   margin: 0;
}

section.four ul {
   width: 100%;
}

section.four li {
   width: 100%;
   box-shadow: var(--box-shadow);
   padding: 20px;
   border-radius: 15px;
   height: 300px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
       -ms-flex-direction: column;
           flex-direction: column;
   text-align: center;
   display: none;
}

section.four li.active {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
}

section.four li .front {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   font-weight: bold;
}

section.four li .front,
section.four li .back {
   height: 50%;
   overflow-y: auto;
   width: 100%;
}

section.four li .front {
   margin-bottom: 10px;
   padding-bottom: 10px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

section.four li .back {
}

section.four .left-right-buttons {
   display: none;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   gap: 20px;
   margin-top: 20px;
   position: relative;
}

section.four .left-right-buttons .left-button,
section.four .left-right-buttons .right-button {
   height: 30px;
   width: 30px;
   background: rgba(var(--purple-rgb), 1);
   border-radius: 50px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   cursor: pointer;
   padding: 0;
}

section.four .left-right-buttons .left-button svg,
section.four .left-right-buttons .right-button svg {
   height: 15px;
   width: 15px;
}

section.four .left-right-buttons .card-count {
   position: absolute;
   top: 50%;
   right: 0;
   -webkit-transform: translateY(-50%);
           transform: translateY(-50%);
}

section.four .cards-generated {
   display: none;
   margin-top: 20px;
   text-align: center;
}

section.five ul {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
       -ms-flex-direction: row;
           flex-direction: row;
   padding: 10px;
   gap: 0;
   -ms-flex-negative: 0;
       flex-shrink: 0;
   gap: 40px;
}

section.five li {
   width: 25%;
   height: 100%;
   box-shadow: var(--box-shadow);
   background: rgba(var(--milk-white-rgba), 1);
   border-radius: 10px;
   overflow: hidden;
}

section.five li:nth-child(even) {
   -webkit-transform: translateY(35%);
           transform: translateY(35%);
}

section.five img {
   height: 55%;
}

section.five .card-footer {
   padding: 15px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
       -ms-flex-direction: column;
           flex-direction: column;
   gap: 20px;
}

section.five .likes {
   text-align: left;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 10px;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
}

section.five svg {
   width: 20px;
   height: 20px;
}

section.five .user-info {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   gap: 10px;
   text-align: left;
}

section.five .user-info p {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 5;
   overflow: hidden;
}

section.five a {
   margin-top: 10px;
   width: 100%;
}

section.six a {
   margin: 0 auto 40px;
   margin-bottom: 30px;
}

/*  */

/*  */

section.value-props ul {
   display: -ms-grid;
   display: grid;
       grid-template-rows: repeat(3, 1fr);
   -webkit-box-align: stretch;
       -ms-flex-align: stretch;
           align-items: stretch;
   gap: 50px;
}

section.value-props ul li {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   width: 100%;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   gap: 40px;
   position: relative;
}

section.value-props ul li:nth-child(2) .on-right p {
   margin-left: auto;
   text-align: right;
}

section.value-props ul li:nth-child(2) .on-left object {
   float: left;
}

section.value-props ul li .on-left {
   position: relative;
}

section.value-props ul li object {
   margin: 0;
   float: right;
}

section.value-props ul li .on-left::after {
   content: '';
   position: absolute;
   top: 50%;
   left: 50%;
   width: 200px;
   height: 200px;
   background: -webkit-radial-gradient(
      circle,
      rgba(var(--purple-rgb), 0.8),
      rgba(var(--blue-rgb), 0.8)
   );
   background: radial-gradient(
      circle,
      rgba(var(--purple-rgb), 0.8),
      rgba(var(--blue-rgb), 0.8)
   );
   border-radius: 50%;
   -webkit-filter: blur(90px);
           filter: blur(90px);
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
   -webkit-animation: pulse 3s infinite;
           animation: pulse 3s infinite;
   z-index: -1;
}

@-webkit-keyframes pulse {
   0%,
   100% {
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1);
   }
   50% {
      -webkit-transform: translate(-50%, -50%) scale(1.2);
              transform: translate(-50%, -50%) scale(1.2);
   }
}

@keyframes pulse {
   0%,
   100% {
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1);
   }
   50% {
      -webkit-transform: translate(-50%, -50%) scale(1.2);
              transform: translate(-50%, -50%) scale(1.2);
   }
}

section.value-props ul li .on-right {
   width: 50%;
   text-align: left;
}

section.value-props ul li .on-right p {
   width: 60%;
   font-size: 1.8rem;
}

/*  */

/* styles for extra small devices (phones, >=100px) */
@media only screen and (min-width: 100px) {
   section.value-props ul li {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
   }

   section.value-props ul li .on-left,
   section.value-props ul li .on-right,
   section.value-props ul li .on-right p,
   section.value-props ul li:nth-child(2) .on-right p {
      width: 100%;
      text-align: center;
   }

   section.value-props ul li object {
      width: 100%;
   }

   section.value-props ul li:nth-child(2) {
      text-align: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
   }

   section.value-props ul li:nth-child(2) .on-right {
      text-align: center;
   }

   section.two li img {
      width: 150px;
   }

   section.six a {
      width: 100%;
   }

   section.two {
      margin: 0 0 100px;
   }

   section.two .scrolling-logos::before {
      background: -webkit-linear-gradient(left, #fef6f7, #fff0);
      background: linear-gradient(to right, #fef6f7, #fff0);
   }
}

/* styles for medium devices (tablets, >=768px) */
@media only screen and (min-width: 768px) {
   section.value-props ul li {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
   }

   section.value-props ul li .on-left,
   section.value-props ul li .on-right {
      width: 50%;
      text-align: left;
   }

   section.value-props ul li .on-right p,
   section.value-props ul li:nth-child(2) .on-right p {
      width: 60%;
      text-align: left;
   }

   section.value-props ul li:nth-child(2) .on-right p {
      text-align: right;
   }

   section.value-props ul li object {
      width: 80%;
   }

   section.value-props ul li:nth-child(2) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
      text-align: right;
   }

   section.value-props ul li:nth-child(2) .on-right {
      text-align: right;
      margin-left: auto;
   }

   section.two li img {
      width: 90%;
   }

   section.six a {
      width: 30%;
   }

   section.two {
      margin: 0 0 150px;
   }

   section.two .scrolling-logos::before {
      background: -webkit-linear-gradient(left, #f5f2fc, rgba(255, 255, 255, 0));
      background: linear-gradient(to right, #f5f2fc, rgba(255, 255, 255, 0));
   }
}

/* styles for large devices (desktops, >=992px) */
@media only screen and (min-width: 992px) {
}

/* styles for extra large devices (large desktops, >=1200px) */
@media only screen and (min-width: 1200px) {
}

/* styles for extra extra large devices (full-screen monitors, >=1400px) */
@media only screen and (min-width: 1400px) {
}
