section.one {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

section.one .error {
  position: absolute;
  top: 100%;
}

section.one .right-part {
  justify-content: center;
  align-items: center;
  display: flex;
}

section.one .highlight {
  display: inline-flex;
  position: relative;
}

section.one .highlight:after {
  content: "";
  background: rgba(var(--purple-rgb), 1);
  border-radius: 50px;
  width: 3px;
  height: 60%;
  animation: .6s linear infinite blink;
  position: absolute;
  top: 50%;
  right: -3px;
  transform: translateY(-50%);
}

section.one .highlight span.period {
  color: rgba(var(--charcoal-rgb), 1);
}

section.one .highlight span.isSpace {
  margin: 0 5px;
}

@keyframes blink {
  0%, 49% {
    opacity: 1;
  }

  50%, 99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

section.two .scrolling-logos {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

section.two .scrolling-logos ul {
  gap: 0;
}

section.two .scrolling-logos:before {
  content: "";
  z-index: 1;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

section.two .scrolling-logos:after {
  content: "";
  z-index: 1;
  background: linear-gradient(to left, #fffbfb, #fff0);
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

section.two .scrolling-logos ul {
  flex: 0 0 100%;
  animation: 25s linear infinite scroll;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

section.two li {
  width: 100%;
  margin: 0 30px;
}

section.two h1, section.three h1, section.value-props h1, section.five h1 {
  margin-bottom: 30px;
}

section.three .left-part {
  width: 40%;
}

section.three ul {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

section.three button {
  border: 1px solid rgba(var(--blue-rgb), 1);
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  height: 100%;
  display: flex;
}

section.three button:hover {
  background: rgba(var(--blue-rgb), 1);
}

section.three button.active {
  background: rgba(var(--blue-rgb), 1);
  color: rgba(var(--milk-white-rgba), 1);
}

section.three i {
  font-size: 1.8rem;
}

section.three img {
  display: none;
}

section.three img.active {
  display: block;
}

section.three .right-part {
  width: 70%;
}

section.four button {
  width: fit-content;
  margin: 0;
}

section.four ul {
  width: 100%;
}

section.four li {
  box-shadow: var(--box-shadow);
  text-align: center;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 300px;
  padding: 20px;
  display: none;
}

section.four li.active {
  display: flex;
}

section.four li .front {
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
}

section.four li .front, section.four li .back {
  width: 100%;
  height: 50%;
  overflow-y: auto;
}

section.four li .front {
  border-bottom: 1px solid #0003;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

section.four .left-right-buttons {
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  display: none;
  position: relative;
}

section.four .left-right-buttons .left-button, section.four .left-right-buttons .right-button {
  background: rgba(var(--purple-rgb), 1);
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
}

section.four .left-right-buttons .left-button svg, section.four .left-right-buttons .right-button svg {
  width: 15px;
  height: 15px;
}

section.four .left-right-buttons .card-count {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

section.four .cards-generated {
  text-align: center;
  margin-top: 20px;
  display: none;
}

section.five ul {
  flex-direction: row;
  flex-shrink: 0;
  gap: 40px;
  padding: 10px;
  display: flex;
}

section.five li {
  box-shadow: var(--box-shadow);
  background: rgba(var(--milk-white-rgba), 1);
  border-radius: 10px;
  width: 25%;
  height: 100%;
  overflow: hidden;
}

section.five li:nth-child(2n) {
  transform: translateY(35%);
}

section.five img {
  height: 55%;
}

section.five .card-footer {
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  display: flex;
}

section.five .likes {
  text-align: left;
  align-items: center;
  gap: 10px;
  display: flex;
}

section.five svg {
  width: 20px;
  height: 20px;
}

section.five .user-info {
  text-align: left;
  align-items: center;
  gap: 10px;
  display: flex;
}

section.five .user-info p {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

section.five a {
  width: 100%;
  margin-top: 10px;
}

section.six a {
  margin: 0 auto 30px;
}

section.value-props ul {
  display: -ms-grid;
  grid-template-rows: repeat(3, 1fr);
  align-items: stretch;
  gap: 50px;
  display: grid;
}

section.value-props ul li {
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

section.value-props ul li:nth-child(2) .on-right p {
  text-align: right;
  margin-left: auto;
}

section.value-props ul li:nth-child(2) .on-left object {
  float: left;
}

section.value-props ul li .on-left {
  position: relative;
}

section.value-props ul li object {
  float: right;
  margin: 0;
}

section.value-props ul li .on-left:after {
  content: "";
  background: -webkit-radial-gradient(circle, rgba(var(--purple-rgb), .8), rgba(var(--blue-rgb), .8));
  background: radial-gradient(circle, rgba(var(--purple-rgb), .8), rgba(var(--blue-rgb), .8));
  filter: blur(90px);
  z-index: -1;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  animation: 3s infinite pulse;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes pulse {
  0%, 100% {
    transform: translate(-50%, -50%)scale(1);
  }

  50% {
    transform: translate(-50%, -50%)scale(1.2);
  }
}

section.value-props ul li .on-right {
  text-align: left;
  width: 50%;
}

section.value-props ul li .on-right p {
  width: 60%;
  font-size: 1.8rem;
}

@media only screen and (width >= 100px) {
  section.value-props ul li {
    flex-direction: column;
  }

  section.value-props ul li .on-left, section.value-props ul li .on-right, section.value-props ul li .on-right p, section.value-props ul li:nth-child(2) .on-right p {
    text-align: center;
    width: 100%;
  }

  section.value-props ul li object {
    width: 100%;
  }

  section.value-props ul li:nth-child(2) {
    text-align: center;
    flex-direction: column;
  }

  section.value-props ul li:nth-child(2) .on-right {
    text-align: center;
  }

  section.two li img {
    width: 150px;
  }

  section.six a {
    width: 100%;
  }

  section.two {
    margin: 0 0 100px;
  }

  section.two .scrolling-logos:before {
    background: linear-gradient(to right, #fef6f7, #fff0);
  }
}

@media only screen and (width >= 768px) {
  section.value-props ul li {
    flex-direction: row;
  }

  section.value-props ul li .on-left, section.value-props ul li .on-right {
    text-align: left;
    width: 50%;
  }

  section.value-props ul li .on-right p, section.value-props ul li:nth-child(2) .on-right p {
    text-align: left;
    width: 60%;
  }

  section.value-props ul li:nth-child(2) .on-right p {
    text-align: right;
  }

  section.value-props ul li object {
    width: 80%;
  }

  section.value-props ul li:nth-child(2) {
    text-align: right;
    flex-direction: row-reverse;
  }

  section.value-props ul li:nth-child(2) .on-right {
    text-align: right;
    margin-left: auto;
  }

  section.two li img {
    width: 90%;
  }

  section.six a {
    width: 30%;
  }

  section.two {
    margin: 0 0 150px;
  }

  section.two .scrolling-logos:before {
    background: linear-gradient(to right, #f5f2fc, #fff0);
  }
}
/*# sourceMappingURL=index.1f38e1b4.css.map */
